<div class="signature-container">
    <canvas #signatureCanvas class="signature-canvas"></canvas>
    <div class="button-group">
        <button pButton type="button" icon="fa fa-trash" class="p-button-outlined p-button-rounded" label="Clear"
            (click)="clearSignature()"></button>
        <button pButton type="button" icon="fa fa-arrow-up" class="p-button-outlined p-button-info p-button-rounded"
            label="TTD" (click)="showModal()"></button>
        <!-- <button (click)="clearSignature()">Clear Pad {{ padId }}</button>
        <button (click)="saveSignature()">Save Pad {{ padId }}</button> -->
    </div>
</div>
<p-dialog header="TTD" [modal]="true" [(visible)]="isModal" [modal]="true" [closable]="true"
    [style]="{ width: '90vw', height: '90vh' }" [resizable]="false" class="fullscreen-dialog">
    <div class="signature-container">
        <canvas #signatureCanvasPop class="signature-canvas-pop"></canvas>
    </div>
    <ng-template pTemplate="footer">
        <button pButton icon="fa fa-save" class="p-button-success p-button-rounded" type="button" (click)="saveTTD()"
            label="Simpan"></button>
        <button pButton type="button" icon="fa fa-trash" class="p-button-outlined p-button-rounded" label="Clear"
            (click)="clearSignaturePop()"></button>
        <button pButton icon="fa fa-arrow-down" class="p-button-danger p-button-rounded" type="button"
            (click)="isModal = false" label="Batal" class="p-mr-2"></button>
    </ng-template>
</p-dialog>