<div class="camera-container">
    <div class="p-grid">
        <div class="p-col-6">
            <video #video autoplay></video>
            <div class="p-grid" style="text-align: left;">
                <div class="p-col-12 p-md-offset-5 p-md-3">
                    <button pButton type="button" class="p-button-warning" (click)="takePhoto()">Capture Photo</button>
                </div>
            </div>
        </div>
        <div class="p-col-6">
            <canvas #canvas style="display: none;"></canvas>
            <img *ngIf="photo" [src]="photo" alt="Captured Photo" style="margin-top:0" />

        </div>
    </div>
</div>