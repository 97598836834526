import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {
  @ViewChild('signatureCanvas') signatureCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('signatureCanvasPop') signatureCanvasPop!: ElementRef<HTMLCanvasElement>;
  @Input() padId!: string; // Input untuk membedakan instance
  @Output() saveEvent = new EventEmitter<{ padId: string; imageData: string }>();
  private canvas!: HTMLCanvasElement;
  private context!: CanvasRenderingContext2D;
  private canvasPop!: HTMLCanvasElement;
  private contextPop!: CanvasRenderingContext2D;
  private isDrawing = false;
  private isDrawingPop = false;
  isModal: boolean= false
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.canvas = this.signatureCanvas.nativeElement;
      this.context = this.canvas.getContext('2d')!;
      this.initializeCanvas();
    }, 500); 
  }
  showModal(){
    this.isModal = true
    setTimeout(() => {
      this.canvasPop = this.signatureCanvasPop.nativeElement;
      this.contextPop = this.canvasPop.getContext('2d')!;
      this.initializeCanvasPop();
    }, 500); 
   
  }
  private initializeCanvas() {
    this.canvas.width = 200; // Ubah ukuran sesuai kebutuhan
    this.canvas.height = 150;
    this.context.lineWidth = 2; // Ketebalan garis
    this.context.lineCap = 'round'; // Ujung garis bulat
    this.context.strokeStyle = 'black'; // Warna garis

    // Event untuk menggambar
    this.canvas.addEventListener('mousedown', this.startDrawing.bind(this));
    this.canvas.addEventListener('mousemove', this.draw.bind(this));
    this.canvas.addEventListener('mouseup', this.stopDrawing.bind(this));
    this.canvas.addEventListener('mouseleave', this.stopDrawing.bind(this));

    this.canvas.addEventListener('touchstart', this.startDrawing.bind(this));
    this.canvas.addEventListener('touchmove', this.draw.bind(this));
    this.canvas.addEventListener('touchend', this.stopDrawing.bind(this));
  }
  private initializeCanvasPop() {
    this.canvasPop.width = this.canvasPop.parentElement?.clientWidth || window.innerWidth;
    this.canvasPop.height =  window.innerHeight - 200; //this.canvasPop.parentElement?.clientHeight ||
  
    this.contextPop.lineWidth = 10; // Ketebalan garis
    this.contextPop.lineCap = 'round'; // Ujung garis bulat
    this.contextPop.strokeStyle = 'black'; // Warna garis

    // Event untuk menggambar
    this.canvasPop.addEventListener('mousedown', this.startDrawingPop.bind(this));
    this.canvasPop.addEventListener('mousemove', this.drawPop.bind(this));
    this.canvasPop.addEventListener('mouseup', this.stopDrawingPop.bind(this));
    this.canvasPop.addEventListener('mouseleave', this.stopDrawingPop.bind(this));

    this.canvasPop.addEventListener('touchstart', this.startDrawingPop.bind(this));
    this.canvasPop.addEventListener('touchmove', this.drawPop.bind(this));
    this.canvasPop.addEventListener('touchend', this.stopDrawingPop.bind(this));
  }

  // Mulai menggambar
  private startDrawing(event: MouseEvent | TouchEvent) {
    this.isDrawing = true;

    const position = this.getPosition(event);
    this.context.beginPath();
    this.context.moveTo(position.x, position.y);
  }

  // Gambar
  private draw(event: MouseEvent | TouchEvent) {
    if (!this.isDrawing) return;

    const position = this.getPosition(event);
    this.context.lineTo(position.x, position.y);
    this.context.stroke();
  }

  // Selesai menggambar
  private stopDrawing() {
    this.isDrawing = false;
    this.context.closePath();
  }

  getPosition(event: MouseEvent | TouchEvent): { x: number; y: number } {
    const rect = this.signatureCanvas.nativeElement.getBoundingClientRect();
  
    // Deteksi apakah event adalah mouse atau touch
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
  
    return {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };
  }

  // Hapus tanda tangan
  clearSignature() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // console.log(`Signature pad ${this.padId} cleared`);
  }
  clearSignaturePop() {
    this.contextPop.clearRect(0, 0, this.canvasPop.width, this.canvasPop.height);
    // console.log(`Signature pad ${this.padId} cleared`);
  }

  // Mulai menggambar
  private startDrawingPop(event: MouseEvent | TouchEvent) {
    this.isDrawingPop = true;

    const position = this.getPositionPop(event);
    this.contextPop.beginPath();
    this.contextPop.moveTo(position.x, position.y);
  }

  // Gambar
  private drawPop(event: MouseEvent | TouchEvent) {
    if (!this.isDrawingPop) return;

    const position = this.getPositionPop(event);
    this.contextPop.lineTo(position.x, position.y);
    this.contextPop.stroke();
  }

  // Selesai menggambar
  private stopDrawingPop() {
    this.isDrawingPop = false;
    this.contextPop.closePath();
  }

  getPositionPop(event: MouseEvent | TouchEvent): { x: number; y: number } {
    const rect = this.signatureCanvasPop.nativeElement.getBoundingClientRect();
  
    // Deteksi apakah event adalah mouse atau touch
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
  
    return {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };
  }

  // Simpan tanda tangan sebagai Base64
  saveSignature() {
    const signatureDataUrl = this.canvas.toDataURL('image/png');
    // console.log(`Signature pad ${this.padId} saved:`, signatureDataUrl);
    // Kirim signatureDataUrl ke backend, jika perlu
    // Emit event untuk parent component
    this.saveEvent.emit({ padId: this.padId, imageData: signatureDataUrl });
  }

  getImageData() {
    return this.canvas.toDataURL('image/png');
  }

  setImageData(imageData: string): void {
    const img = new Image();
    img.src = imageData; // Data Base64
    img.onload = () => {
      const canvas = this.signatureCanvas.nativeElement;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Membersihkan canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Merender gambar ke canvas
      }
    };
  }
  
  saveTTD(){
    const signaturePopDataUrl = this.signatureCanvasPop.nativeElement.toDataURL('image/png');

    // Set the image data to signatureCanvas
    this.setImageData(signaturePopDataUrl);
    this.isModal = false
  }
}
